<template>
  <div>
    <MainIntro />
    <MainContent />
  </div>
</template>
<script setup lang="ts">
useHead({
  title: "케이씨파워 - 메인페이지",
  meta: [
    {
      name: "description",
      content:
        "케이씨파워 공식 웹사이트에 오신 것을 환영합니다. 저희는 전기 업종에서 최고의 서비스와 제품을 제공합니다.",
    },
    {
      name: "keywords",
      content: "케이씨파워, kcpower, 전기, 수배전반, 자동제어",
    },
    { property: "og:title", content: "케이씨파워 - 메인페이지" },
    {
      property: "og:description",
      content:
        "케이씨파워 공식 웹사이트에 오신 것을 환영합니다. 저희는 전기 업종에서 최고의 서비스와 제품을 제공합니다.",
    },
    {
      property: "og:image",
      content: "https://kcpower.co.kr/_nuxt/logo.Tv1edgK2.png",
    },
  ],
  link: [
    {
      rel: "canonical",
      href: "https://kcpower.co.kr",
    },
  ],
});
</script>
