<template>
 

<main data-aos="fade-up" data-aos-duration="1000" data-aos-once="true" class="flex flex-col items-center bg-red-800 p-20">
<p class="mb-10 lg:mb-5 text-3xl text-white">          
        Latest news
</p>
<section class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-20 lg:gap-10 xl:gap-20 bg-red-800 p-0 md:p-15 lg:p-5">
    <article class="relative outline-offset-[15px] outline outline-1 outline-white max-w-sm h-full bg-white border border-white rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <img class="rounded-t-lg w-full h-48" src="https://www.raonnews.com/data/photos/20220935/art_16620810381362_ae7212.jpg" alt="" />
        <div class="p-5">
            
            <h5 class="mb-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white line-clamp-2">수배전반 전문 (주)케이씨파워 본사 확장</h5>
            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400 line-clamp-3">기존 인천광역시 남동구에 위치한 공장이 자연스레 2공장이 되고, 송도 국제도시와 가장 밀접한 큰 대로변에 위치한 새 공장으로 본사를 확장했다.
                    수배전반을 기반으로 한 (주)케이씨 파워는 밀려오는 분로리액터, 여러 특허형 왜 함들의 발주로 인해 1공장(본사)/2공장 모두 매입해 사용할 수밖에 없는 호재를 맞았다.</p>
        </div>
        <a href="http://www.beyondpost.co.kr/view.php?ud=202208311622254656cf2d78c68_30" class="absolute -bottom-14 -right-5 inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg">
            자세히 보기
            <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
            </svg>
        </a>
        <Icon name="ep:arrow-right-bold" class="absolute -right-14 bottom-40 text-white inline-block" size="30px"/>  
    </article>
    <article class="relative outline-offset-[15px] outline outline-1 outline-white max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <img class="rounded-t-lg w-full h-48" src="https://cgeimage.commutil.kr/phpwas/restmb_allidxmake.php?pp=002&idx=3&simg=20220901124005063476cf2d78c681245156135.jpg&nmt=30" alt="" />
        <div class="p-5">
            <h5 class="mb-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white line-clamp-2">케이씨파워, 누전 및 폭우침수 피해 방지형 '외함 변압기·개폐기' 선보여</h5>
            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400 line-clamp-3">기존 인천광역시 남동구에 위치한 공장이 자연스레 2공장이 되고, 송도 국제도시와 가장 밀접한 큰 대로변에 위치한 새 공장으로 본사를 확장했다.
                    수배전반을 기반으로 한 (주)케이씨 파워는 밀려오는 분로리액터, 여러 특허형 왜 함들의 발주로 인해 1공장(본사)/2공장 모두 매입해 사용할 수밖에 없는 호재를 맞았다.</p>            
        </div>
        <a href="http://www.beyondpost.co.kr/view.php?ud=202208311622254656cf2d78c68_30" class="absolute -bottom-14 -right-5 inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg">
            자세히 보기
            <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
            </svg>
        </a>      
        <Icon name="ep:arrow-right-bold" class="absolute -right-14 bottom-40 text-white inline-block" size="30px"/>  
    </article>    

    <article class="relative outline-offset-[15px] outline outline-1 outline-white max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <img class="rounded-t-lg w-full h-48" src="https://www.dailysecu.com/news/photo/202303/144656_169555_747.jpg" alt="" />
        <div class="p-5">        
            <h5 class="mb-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white line-clamp-2">케이씨파워, 남동구 경영인 연합회 모범 기업인상 수상</h5>        
            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400 line-clamp-3">인천 남동구 라마다호텔에서 열린 2023 남동구 경영인 연합회 정기총회에서 남동공단 대표 기업 중 한 곳인 (주)케이씨파워가 훈격 인천광역시장 모범 기업인상을 받았다.</p>
        </div>
        <a href="http://www.beyondpost.co.kr/view.php?ud=202208311622254656cf2d78c68_30" class="absolute -bottom-14 -right-5 inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg">
            자세히 보기
            <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
            </svg>
        </a>
    </article>
</section>
</main>

</template>
<script>
import { ref } from 'vue';

export default {
    name: 'MainIntro',
    setup() {
        // Add your component's logic here
        return {
            // Add any reactive data or methods here
        };
    }
}
</script>