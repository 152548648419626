<template>
  <div class="flex flex-col justify-between items-center h-full mt-40">
      <!-- <p data-aos="fade-up" data-aos-duration="1000" class="text-3xl md:text-4xl lg:text-5xl text-center m-20">Who we are</p> -->
      <div data-aos="fade-up" data-aos-duration="1000"  class="m-5 flex flex-col items-center justify-center">        
        <p class="font-light text-gray-500 text-2xl md:text-2xl lg:text-3xl mb-1">
          오직
        </p>        
        <p class="font-light text-gray-500 text-2xl md:text-2xl lg:text-3xl mb-2">          
          정도경영 품질경영 신뢰경영
        </p>       
        <br> 
        <p class="text-3xl md:text-4xl lg:text-5xl mb-10">          
          우리의 전문 기술로 세상을 밝힙니다.
        </p>
      </div>

      <div data-aos="fade-up" data-aos-duration="1000" class="p-8 flex flex-col md:flex-row justify-center gap-x-70">
        <div class="flex flex-col items-center m-5 md:m-20">            
          <img src="~/assets/img/first-svg.svg" alt="First SVG" width="60" height="70">
          <span class="pt-2 text-gray-500">업력</span>
          <p class="tracking-tighter text-red-800 text-1xl md:text-4xl lg:text-7xl">32년</p>
        </div>
        <div class="flex flex-col items-center m-5 md:m-20">            
          <img src="~/assets/img/second-svg.svg" alt="Second SVG" width="60" height="70">
          <span class="pt-1 text-gray-500">특허</span>
          <p class="tracking-tighter text-red-800 text-1xl md:text-4xl lg:text-7xl">30개</p>
        </div>
        <div class="flex flex-col items-center m-5 md:m-20">            
          <img src="~/assets/img/third-svg.svg" alt="Third SVG" width="60" height="70">
          <span class=" text-gray-500">시공횟수<br></span>
          <p class="tracking-tighter text-red-800 text-1xl md:text-4xl lg:text-7xl">200건</p>
        </div>
      </div>
      
      <p class="font-light text-gray-500 text-1xl md:text-2xl lg:text-3xl mt-20 md:mt-40 mb-10">          
          소개 영상
      </p>       
      <video class="w-2/3 mb-40" controls autoplay>
        <source src="/videos/intro-video.mp4" type="video/mp4">
      </video>      
  </div>

  


</template>

<script>
import { ref } from 'vue';

export default {
    name: 'MainIntro',
    setup() {
        // Add your component's logic here
        return {
            // Add any reactive data or methods here
        };
    }
}
</script>